import api from "./api-service";
import { stringify } from "querystring";

const url = "flow-types";

export const findAll = (filter) => api().get(`${url}?${stringify(filter)}`);

export const findOne = (id) => api().get(`${url}/${id}`);

export const update = (flowType) => api().put(url, flowType);

export const create = (flowType) => api().post(url, flowType);
